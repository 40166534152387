

a:link, a:visited, a:hover, a:active {
    color: #635ee7;
    text-decoration: none;
}

.pageRangeHl {
    background-color: #b5b3eb;
}

.splash {
    display: block;
    color: white;
    width: 100%;
    height: 700px;
    background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(../public/images/cabin.jpg) 50% 50%;
    background-size: cover;
}

.splash .footer {
    display: block;
    color: rgba(255, 255, 255, 0.5);
    padding: 20px;
}
  
.subSplashContent {
    display: block;
}
  
@media only screen and (max-width: 768px) {
    .splash {
        height: 600px;
    }

    .subSplashContent {
        text-align: center;
        font-size: 0.8em;
    }

    .subSplashContent ol {
        text-align: left;
    }

    .footer {
        display: block;
        color: rgba(0, 0, 0, 0.5);
        background-color: rgba(0, 0, 0, 0.1);
        margin-top: 50px;
        padding: 20px;
    }
} 